<template>
	<div class="productfun">
		<div class="left">
			<img :src="data.image" alt="">
		</div>
		<div class="right">
			<h4>{{data.name}}</h4>
			<p>{{data.text}}</p>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			data:{
				default:{}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.productfun{
		display: flex;
		align-items: center;
		width: 11.052631rem;
		height: 4.736842rem;
		justify-content: space-between;
		.left{
			width: 3.947368rem;
			height: 3.947368rem;
			background-color: #cfd4fd;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			// box-shadow: 0px 0px 0.131578rem 0.131578rem #ccc;
			img{
				width: auto;
				max-width: 70%;
			}
		}
		.right{
			width: 6.052631rem;
			h4{
				font-size: 0.631578rem;
				color: #333;
			}
			p{
				font-size: 0.526315rem;
				color: #666;
			}
		}
	}
</style>
